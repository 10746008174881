import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { getApi, postApi } from "./Helper/Axios";
import Pagination from "./Pagination";
import Popup from "./Popup";
import MyContext from "./MyContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { convertUTCtoDate } from "./Helper/helper";
export default function TableDisplay({
  tableurl, title, deleteurl, deleteallurl, category, formConfig, initialValues, addurl, updateurl, updatteurl, serachCategory, statusurl, axiosHeader, replyMessage, autoMessage, 
}) {
  let permission_module;
  const location = useLocation();
  const urlName = location.pathname;
  let module = urlName.replace(/^\/|\/$/g, "");

  const user = useSelector((state) => state.auth.user);
  const isSuperAdmin = user && user.is_superadmin;
  const permission = user && JSON.parse(user?.permission);

  if (permission !== null) {
    permission_module = { ...permission[module] };
  }

  if (isSuperAdmin === 1) {
    permission_module = {
      read: true,
      create: true,
      update: true,
      delete: true,
    };
  }

  const [data, setData] = useState([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [updatedata, setUpdateData] = useState({});
  const [isupdate, setIsupdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteid, setdeleteid] = useState(0);
  const [id, setId] = useState();
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState("");
  const [isChecked, setIsChecked] = useState(selectedItems?.length === data?.length ? true : false);
  const [count, setCount] = useState(0);

  const successNotify = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const errorNotify = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  /********************** */
  const [shows, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /* ***************************** */
  /* ************ Pagination */
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [pageLoader, setPageLoader] = useState(false);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setSelectedItems([]);
    setCurrentPage(pageNumber);
  };

  /***************** */
  const fetchData = async () => {
    setPageLoader(true);
    let url = `${tableurl}?pagecount=${currentPage}` + (search ? `&search=${search}` : "");
    await getApi(url, true)
      .then((res) => {        
        setData(res.data);
        setTotalPages(res.totalPages);
        setCurrentPage(res.currentPage);
        setCount(res.count);
        setPageLoader(false);
        if (res.status === 401) {
          setError(res.data);
        }
      })
      .catch((err) => {
        console.log("Fetching data errror", err);
        setPageLoader(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, search, selectedItems]);

  const handleDelete = async (id) => {
    const query = category === "Search Engine" ? "searchengine" : category === "Trending Search" ? "trending" : category === "Game" ? "game" : category === "Top" ? "top" : "";
    const apiEndpoint = category === "More Apps" ? `${deleteurl}?moreapp=${id}` : `${deleteurl}?${query}=${id}`;
    const requestData = category === "More Apps" ? true : { is_archive: true, archive_at: Date.now() };

    try {
      const res = await getApi(apiEndpoint, requestData);
      if (res.status === 200) {
        fetchData();
        errorNotify(res.message);
        handleDeleteClose();
        if (data.length === 0) {
          setCurrentPage(currentPage - 1);
        }
        setCurrentPage(currentPage);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteAll = async () => {
    await getApi(`${deleteallurl}/${selectedItems}`, true)
      .then((res) => {
        // if (res.status === 200) {
        setIsChecked(false);
        errorNotify(res.message);
        handleDeleteClose();
        setSelectedItems([]);
        fetchData();
        // }
      })
      .catch((err) => console.log(err));
  };

  const statuscheckboxchange = (e) => {
    let i = [];
    if (e === true) {
      setIsChecked(true);
      i = data && data?.map((item) => item.id);
    }
    if (e === false) {
      setIsChecked(false);
      i = [];
    }
    setSelectedItems(i);
  };

  const handleUpdate = async (id) => {
    let query =
      category === "Search Engine"
        ? "searchengine"
        : category === "Trending Search"
        ? "trending"
        : category === "Game"
        ? "game"
        : category === "Top"
        ? "top"
        : category === "More Apps"
        ? "moreapp"
        : "";
    await getApi(`${updateurl}?${query}=${id}`, true)
      .then((res) => {
        setUpdateData(res.data);
        setIsupdate(true);
        handleShow();
      })
      .catch((err) => console.log(err));
  };

  const value = {
    title: category,
    isupdate: isupdate,
    handleClose: handleClose,
    isShow: shows,
    formConfig: formConfig,
    url: addurl,
    data: isupdate ? updatedata : initialValues,
    fetchData: fetchData,
    updatteurl: updatteurl,
    id: id,
    axiosHeader: axiosHeader,
  };

  const handleStatusChange = async (statuss, id) => {
    const newdata = {
      status: statuss,
    };
    await postApi(`${statusurl}?status=${id}`, newdata, true)
      .then((res) => {
        if (res.status === 200) {
          successNotify(res.message);
          fetchData();
        }
      })
      .catch((err) => console.log(err));
  };

  const toggleSelectItem = (itemId) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(itemId)) {
        return prevSelected.filter((id) => id !== itemId);
      } else {
        return [...prevSelected, itemId];
      }
    });
    if (data?.length === data?.length) {
      setIsChecked(true);
    }
  };

  return (
    <MyContext.Provider value={value}>
      {error ? (
        <div className="alert-danger text-center alert">
          <p>{error}</p>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between userr">
            <div className="title w-60 d-flex justify-content-between align-middle align-items-center user-info">
              <h4 className="d-flex justify-content-between marginright align-items-center">
                {category}
                {"   "}
              </h4>
              {count === 1 ? (
                <h6 className="m-0">( {count} Record )</h6>
              ) : count > 0 ? (
                <h6>( {count} Records )</h6>
              ) : null}
            </div>
            {permission_module?.create === true ? (
              <div className="w-50 d-flex flex-row-reverse user-info ">
                {(category !== "Exspeed User" && category !== "Koi Users") && <button
                  className="mybtn"
                  onClick={() => {
                    handleShow();
                    setIsupdate(false);
                  }}
                >{`add ${category}`}</button>}

                {category === "Search Engine" || category === "Trending Search" || category === "Game" || category === "Exspeed User" || category === "Koi Users" ? (
                  <div className="search marginright">
                    <input
                      type="text"
                      onChange={(e) => {setSearch(e.target.value)}}
                      className="search__input marginright popup__input"
                      placeholder={`Search ${serachCategory}`}
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path
                        fill="black"
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                      />
                    </svg>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <Table responsive="sm" className={`mytable ${category === "Exspeed User" ? "users" : ""} ${category}`}>
            <thead>
              {selectedItems.length === 0 || (isChecked === true && selectedItems.length !== 0) || (isChecked === false && selectedItems.length === data.length) ? (
                <tr>
                  <th>
                    {category !== "Exspeed User" && category !== "Koi Users" && category !== "Payment" && data?.length > 0 && permission_module?.delete === true ? (
                      <>
                        <div className="checkbox marginright">
                          <input
                            type="checkbox"
                            className="checkbox__input"
                            checked={isChecked && selectedItems?.length === data?.length}
                            onChange={() => {statuscheckboxchange(!isChecked)}}
                          />
                          <span className="checkbox__in">
                            <span className="checkbox__tick"></span>
                          </span>
                        </div>
                      </>
                    ) : null}
                  </th>
                  {selectedItems?.length > 0 ? (
                    <th colSpan={title.length}>
                      {" "}
                      {selectedItems?.length} Selected
                    </th>
                  ) : (
                    title && title.map((title, index) => <th key={index}>{title}</th>)
                  )}
                  {isChecked === true && selectedItems.length !== 0 ? (
                    <th colSpan={title.length} className="justify-content-end">
                      <Dropdown className={`dotdisplay ${isChecked === true && selectedItems.length !== 0 ? "showw" : ""}`}>
                        <Dropdown.Toggle id="dropdown-basic" className="my-dropdown">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                            <path fill="currentcolor" d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"/>
                          </svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {/* <Dropdown.Item>Action</Dropdown.Item> */}
                          <button className="showWrap auto" type="button" onClick={handleDeleteShow}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                              <path
                                fill="currentcolor"
                                d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                              />
                            </svg>
                            {category === "More Apps" ? "Delete" : "Archive"}
                          </button>
                        </Dropdown.Menu>
                      </Dropdown>
                    </th>
                  ) : category !== "Exspeed User" && category !== "Koi Users" && category !== "Payment" && permission_module?.delete === true && permission_module?.update === true ? (
                    <th>Action</th>
                  ) : null}
                </tr>
              ) : null}
            </thead>
            
            <tbody>
              {!pageLoader ? (
                data && data.length > 0 ? (
                  data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="align-middle">
                        {category !== "Exspeed User" && category !== "Koi Users" && category !== "Payment" && permission_module?.delete ? (
                          <div className="checkbox">
                            <input
                              type="checkbox" className="checkbox__input"
                              onChange={() => toggleSelectItem(row.id)} checked={selectedItems.includes(row.id)}
                            />
                            <span className="checkbox__in">
                              <span className="checkbox__tick"></span>
                            </span>
                          </div>
                        ) : null}
                      </td>
                      {title.map((title, colIndex) => (
                        <td key={colIndex} className="align-middle">
                          {title === "icon" ? (
                            <div className="imageWrap">
                              <img src={`${process.env.REACT_APP_UPLOADS_URL}icons/${row.icon}`} alt="icon"/>
                            </div>
                          ) : title === "logo" ? (
                            <div className="imageWrap">
                              <img src={`${process.env.REACT_APP_UPLOADS_URL}logos/${row.logo}`} alt="logo"/>
                            </div>
                          ) : title === "status" ? (
                            <label className="switch">
                              <input type="checkbox" checked={row[title]} onChange={() => handleStatusChange(row[title] === true ? false : true, row.id)}/>
                              <span className="slider round"></span>
                            </label>
                          ) : (
                            title === "createdAt" ? convertUTCtoDate(row[title]) : row[title]
                          )}
                        </td>
                      ))}
                      {category !== "Exspeed User" && category !== "Koi Users" && category !== "Payment" && selectedItems.length === 0 ? (
                        <td className="align-middle">
                          <div className="d-flex">
                            {permission_module?.update ? (
                              <button className="showWrap marginright" onClick={() => { handleUpdate(row.id); setId(row.id);}}> 
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                  <path
                                    fill="currentcolor"
                                    d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                                  />
                                </svg>
                              </button>
                            ) : null}
                            {permission_module?.delete ? (
                              <button className="showWrap" onClick={() => {handleDeleteShow(); setdeleteid(row.id);}}>
                                {category === "More Apps" ? (
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path
                                      fill="currentcolor"
                                      d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                                    />
                                  </svg>
                                ) : (
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                      fill="currentcolor"
                                      d="M32 32H480c17.7 0 32 14.3 32 32V96c0 17.7-14.3 32-32 32H32C14.3 128 0 113.7 0 96V64C0 46.3 14.3 32 32 32zm0 128H480V416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V160zm128 80c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"
                                    />
                                  </svg>
                                )}
                              </button>
                            ) : null}
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={title.length+2} className="text-center">
                      No data available
                    </td>
                  </tr>)
              ) : (
                <tr>
                  <td className="text-center" colSpan={title.length + 1}>
                    <div className="loaderWrap">
                      <div className="loader-15"></div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {error && visibleAlert ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : null}

          <Modal show={showDelete} onHide={handleDeleteClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {category === "More Apps" ? "Delete" : "Archive"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to{" "}
              {category === "More Apps" ? "Delete" : "Archive"} this {category}.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className="mybtn black" onClick={handleDeleteClose}>Cancel</Button>
              {selectedItems.length === 0 ? (
                <Button variant="primary" className="mybtn" onClick={() => handleDelete(deleteid)}>
                  {category === "More Apps" ? "Delete" : "Archive"}
                </Button>
              ) : (
                <Button variant="primary" className="mybtn" onClick={handleDeleteAll}>
                  {category === "More Apps" ? "Delete" : "Archive"}
                </Button>
              )}
            </Modal.Footer>
          </Modal>

          {data?.length > 0 && totalPages > 1 ? (
            <Pagination currentPage={currentPage} totalPages={totalPages} onNext={handleNextPage} onPrev={handlePrevPage} handlePageChange={handlePageChange}/>
          ) : null}
          {shows && <Popup />}
        </div>
      )}
    </MyContext.Provider>
  );
}
