import React, { useState } from "react";
import * as Yup from "yup";
import TableDisplay from "./TableDisplay";

export default function TrendingSearch() {
  const [title, setTitle] = useState(["id", "name", "status"]);
  const formConfig = {
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text",
        placeholder: "enter name",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      name: Yup.string().required("name Required"),
    }),
  };

  const initialValues = {
    name: "",
    status: false,
  };
  return (
    <div>
      <TableDisplay
        category="Trending Search"
        tableurl="get-trending-search"
        title={title}
        deleteurl="archive-trending-search"
        deleteallurl="all-archive-trending-search"
        addurl="add-trending-search"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-trending-search"
        updatteurl="edit-trending-search"
        serachCategory=""
        statusurl="status-trending-search"
        axiosHeader={false}
      />
    </div>
  );
}
