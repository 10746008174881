import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { ReactComponent as SearchEngineSvg } from "../images/search.svg";
import { ReactComponent as SitesSvg } from "../images/sites.svg";
import { ReactComponent as WallpapersSvg } from "../images/wallpapers.svg";
import { ReactComponent as TrendingSearchSvg } from "../images/trending.svg";
import { ReactComponent as GameSvg } from "../images/game.svg";
import { ReactComponent as ArchiveSvg } from "../images/revert.svg";
import { getApi } from "./Helper/Axios";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export default function Archive() {
  let permission_module;
  const location = useLocation();
  const urlName = location.pathname;
  let module = urlName.replace(/^\/|\/$/g, "");

  const user = useSelector((state) => state.auth.user);

  const isSuperAdmin = user && user.is_superadmin;

  const permission = user && JSON.parse(user?.permission);

  if (permission !== null) {
    permission_module = { ...permission[module] };
  }
  if (isSuperAdmin === 1) {
    permission_module = {
      read: true,
      create: true,
      update: true,
      delete: true,
    };
  }
  const [searchEngine, setSearchEngine] = useState();
  const [sites, setSites] = useState();
  const [trendingSearch, setTrendingSearch] = useState();
  const [game, setGame] = useState();
  const [top, setTop] = useState();
  const [wallpaper, setWallpaper] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [modaltext, setmodaltext] = useState("Delete");
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);
  const [deleteid, setdeleteid] = useState(0);
  const [category, setCategory] = useState("");
  const fetchData = async () => {
    await getApi("get-archive-search-engine", true)
      .then((res) => {
        setSearchEngine(res.data.searchengine);
      })
      .catch((err) => {
        console.log("===========", err);
      });
    await getApi("get-archive-trending-search", true)
      .then((res) => {
        setTrendingSearch(res.data.trendingsearch);
      })
      .catch((err) => {
        console.log("===========", err);
      });
    await getApi("get-archive-game", true)
      .then((res) => {
        setGame(res.data);
      })
      .catch((err) => {
        console.log("===========", err);
      });
       await getApi("get-archive-top", true)
         .then((res) => {
           setTop(res.data);
         })
         .catch((err) => {
           console.log("===========", err);
         });
    await getApi("get-archive-wallpaper", true)
      .then((res) => {
        setWallpaper(res.data.wallpapercategory);
      })
      .catch((err) => {
        console.log("===========", err);
      });
    await getApi("get-archive-site", true)
      .then((res) => {
        console.log("----------", res);
        setSites(res.data.sitecategory);
      })
      .catch((err) => {
        console.log("===========", err);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const successNotify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const errorNotify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const handleRevert = async (id, modaltxt = null) => {
    let deleteurl =
      modaltxt === "searchengine"
        ? "restore-archive-search-engine"
        : modaltxt === "trending"
        ? "restore-archive-trending-search"
        : modaltxt === "game"
        ? "restore-archive-game"
        : modaltxt === "top"
        ? "restore-archive-top"
        : modaltxt === "wallpaper"
        ? "restore-archive-wallpaper"
        : modaltxt === "site"
        ? "restore-archive-site"
        : "";
    await getApi(`${deleteurl}?${modaltxt}=${id}`, true)
      .then((res) => {
        if (res.status === 200) {
          fetchData();
          successNotify(res.message);
          handleDeleteClose();
        }
      })
      .catch((err) => console.log(err));
  };
  const handleDelete = async (id, modaltxt) => {
    let deleteurl =
      modaltxt === "searchengine"
        ? "delete-search-engine"
        : modaltxt === "trending"
        ? "delete-trending-search"
        : modaltxt === "game"
        ? "delete-game"
        : modaltxt === "top"
        ? "delete-top"
        : modaltxt === "wallpaper"
        ? "delete-wallpaper"
        : modaltxt === "site"
        ? "delete-site"
        : "";
    await getApi(`${deleteurl}?${modaltxt}=${id}`, true)
      .then((res) => {
        if (res.status === 200) {
          fetchData();
          errorNotify(res.message);
          handleDeleteClose();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <h4 className="d-flex justify-content-between mb-3">Archive</h4>
      <Modal show={showDelete} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modaltext}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {modaltext} this {category}.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="mybtn black"
            onClick={handleDeleteClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="mybtn"
            onClick={() =>
              modaltext === "Delete"
                ? handleDelete(deleteid, category)
                : handleRevert(deleteid, category)
            }
          >
            {modaltext}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="archiveWrap ">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <SearchEngineSvg className="marginright" />
              Search Engine{" "}
            </Accordion.Header>
            <Accordion.Body>
              <div className="d-flex flex-wrap">
                {searchEngine?.length > 0 ? (
                  searchEngine.map((search) => (
                    <div className=" d-flex sitecategorywrap align-items-center">
                      <div className="siteCateIcon marginright p-2">
                        <img
                          src={`${process.env.REACT_APP_UPLOADS_URL}icons/${search.icon}`}
                          alt="imag"
                        />
                      </div>
                      <h6>{search.name}</h6>
                      {permission_module?.delete === true ? (
                        <>
                          <div
                            onClick={() => {
                              setdeleteid(search.id);
                              setCategory("searchengine");
                              setmodaltext("Revert");
                              handleDeleteShow();
                              // handleRevert(search.id, "searchengine");
                            }}
                            className="iconWrap view"
                          >
                            <ArchiveSvg />
                          </div>
                          <div
                            className="iconWrap"
                            onClick={() => {
                              setdeleteid(search.id);
                              setCategory("searchengine");
                              setmodaltext("Delete");
                              handleDeleteShow();
                              // handleDelete(search.id, "searchengine");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                              />
                            </svg>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div className="col-md-12">
                    <div className="not-found">
                      <p>No data found</p>
                    </div>
                  </div>
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <SitesSvg className="marginright" />
              Sites
            </Accordion.Header>
            <Accordion.Body>
              {sites?.length > 0
                ? sites.map((site) => (
                    <div className="sitesWrap align-items-center" key={site.id}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                          <h6 className={site.id}>{site.name}</h6>
                        </div>
                      </div>
                      <div className="sites_list">
                        <div className="d-flex">
                          {site.categoryData?.length > 0 ? (
                            site.categoryData.map((category, categoryIndex) => {
                              return (
                                <div
                                  className="d-flex sitecategorywrap bg align-items-center"
                                  key={category.id}
                                >
                                  <div className="siteCateIcon marginright">
                                    <img
                                      src={`${process.env.REACT_APP_UPLOADS_URL}icons/${category.icon}`}
                                      alt="siteCat"
                                    />
                                  </div>
                                  <div className="siteCatContent">
                                    {category.site_name}
                                  </div>
                                  {permission_module?.delete === true ? (
                                    <>
                                      <div
                                        onClick={() => {
                                          setdeleteid(category.id);
                                          setCategory("site");
                                          setmodaltext("Revert");
                                          handleDeleteShow();
                                          // handleRevert(category.id, "site");
                                        }}
                                        className="iconWrap view"
                                      >
                                        <ArchiveSvg />
                                      </div>
                                      <div
                                        className="iconWrap"
                                        onClick={() => {
                                          setdeleteid(category.id);
                                          setCategory("site");
                                          setmodaltext("Delete");
                                          handleDeleteShow();
                                          // handleDelete(category.id, "site");
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 448 512"
                                        >
                                          <path
                                            fill="currentcolor"
                                            d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                                          />
                                        </svg>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              );
                            })
                          ) : (
                            <div className="col-md-12">
                              <div className="not-found">
                                <p>No data found</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <WallpapersSvg className="marginright" />
              Wallpapers
            </Accordion.Header>
            <Accordion.Body>
              {wallpaper &&
                wallpaper.map((site) => (
                  <div className="sitesWrap align-items-center" key={site.id}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="d-flex">
                        <h6 className={site.id}>{site.name}</h6>
                      </div>
                    </div>
                    <div className="sites_list">
                      <div className="d-flex flex-wrap">
                        {site.categoryData?.length > 0 ? (
                          site.categoryData.map((category, categoryIndex) => {
                            return (
                              <div
                                className="d-flex sitecategorywrap bg Wallpaper align-items-center"
                                key={category.id}
                              >
                                <div className="siteCateIcon Wallpaper marginright">
                                  <img
                                    src={`${process.env.REACT_APP_UPLOADS_URL}images/${category.image}`}
                                    alt="siteCat"
                                  />
                                </div>
                                {permission_module?.delete === true ? (
                                  <>
                                    <div
                                      onClick={() => {
                                        setdeleteid(category.id);
                                        setCategory("wallpaper");
                                        setmodaltext("Revert");
                                        handleDeleteShow();
                                        // handleRevert(category.id, "wallpaper");
                                      }}
                                      className="iconWrap view"
                                    >
                                      <ArchiveSvg />
                                    </div>
                                    <div
                                      className="iconWrap"
                                      onClick={() => {
                                        setdeleteid(category.id);
                                        setCategory("wallpaper");
                                        setmodaltext("Delete");
                                        handleDeleteShow();
                                        // handleDelete(category.id, "wallpaper");
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                      >
                                        <path
                                          fill="currentcolor"
                                          d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                                        />
                                      </svg>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            );
                          })
                        ) : (
                          <div className="col-md-12">
                            <div className="not-found">
                              <p>No data found</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <TrendingSearchSvg className="marginright" />
              Trending Search
            </Accordion.Header>
            <Accordion.Body>
              <div className="d-flex flex-wrap">
                {trendingSearch?.length > 0 ? (
                  trendingSearch.map((search) => (
                    <div className=" d-flex sitecategorywrap align-items-center">
                      <h6>{search.name}</h6>
                      {permission_module?.delete === true ? (
                        <>
                          <div
                            onClick={() => {
                              setdeleteid(search.id);
                              setCategory("trending");
                              setmodaltext("Revert");
                              handleDeleteShow();
                              // handleRevert(search.id, "trending");
                            }}
                            className="iconWrap view"
                          >
                            <ArchiveSvg />
                          </div>
                          <div
                            className="iconWrap"
                            onClick={() => {
                              setdeleteid(search.id);
                              setCategory("trending");
                              setmodaltext("Delete");
                              handleDeleteShow();
                              // handleDelete(search.id, "trending");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                              />
                            </svg>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div className="col-md-12">
                    <div className="not-found">
                      <p>No data found</p>
                    </div>
                  </div>
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <GameSvg className="marginright" />
              Game
            </Accordion.Header>
            <Accordion.Body>
              <div className="d-flex flex-wrap">
                {game?.length > 0 ? (
                  game.map((search) => (
                    <div className="sitecategorywrap align-items-center d-block">
                      <h6>{search.name}</h6>
                      <p>{search.url}</p>
                      {permission_module?.delete === true ? (
                        <>
                          <div
                            onClick={() => {
                              setdeleteid(search.id);
                              setCategory("game");
                              setmodaltext("Revert");
                              handleDeleteShow();
                              // handleRevert(search.id, "game");
                            }}
                            className="iconWrap view"
                          >
                            <ArchiveSvg />
                          </div>
                          <div
                            className="iconWrap"
                            onClick={() => {
                              setdeleteid(search.id);
                              setCategory("game");
                              setmodaltext("Delete");
                              handleDeleteShow();
                              // handleDelete(search.id, "game");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                              />
                            </svg>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div className="col-md-12">
                    <div className="not-found">
                      <p>No data found</p>
                    </div>
                  </div>
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <GameSvg className="marginright" />
              Top
            </Accordion.Header>
            <Accordion.Body>
              <div className="d-flex flex-wrap">
                {top?.length > 0 ? (
                  top.map((search) => (
                    <div className="sitecategorywrap align-items-center d-block">
                      <h6>{search.name}</h6>
                      <p>{search.url}</p>
                      {permission_module?.delete === true ? (
                        <>
                          <div
                            onClick={() => {
                              setdeleteid(search.id);
                              setCategory("top");
                              setmodaltext("Revert");
                              handleDeleteShow();
                              // handleRevert(search.id, "game");
                            }}
                            className="iconWrap view"
                          >
                            <ArchiveSvg />
                          </div>
                          <div
                            className="iconWrap"
                            onClick={() => {
                              setdeleteid(search.id);
                              setCategory("top");
                              setmodaltext("Delete");
                              handleDeleteShow();
                              // handleDelete(search.id, "game");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                              />
                            </svg>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div className="col-md-12">
                    <div className="not-found">
                      <p>No data found</p>
                    </div>
                  </div>
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}
