
const convertUTCtoDate = (dateUTC) => {
    const utcDate = new Date(dateUTC);

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    let day = utcDate.getDate();
    let month = monthNames[utcDate.getMonth()];
    let year = utcDate.getFullYear();
    let hours = utcDate.getHours();
    let minutes = utcDate.getMinutes();
    let seconds = utcDate.getSeconds();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;

    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
    const secondsFormatted = seconds < 10 ? `0${seconds}` : seconds;

    const formattedDate = `${day} ${month} ${year}, ${hours}:${minutesFormatted}:${secondsFormatted} ${ampm}`;

    return formattedDate;
}

export { convertUTCtoDate };