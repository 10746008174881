import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { postApi } from "./Helper/Axios";
import { toast } from "react-toastify";
function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const [serror, setSerror] = useState("");
  const formdata = {
    password: "",
    newpassword: "",
    confirmpassword: "",
  };
  const successNotify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const errorNotify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError("");
      }, 3000); // 3000 milliseconds = 3 seconds
    }
    if (serror) {
      timer = setTimeout(() => {
        setSerror("");
      }, 3000); // 3000 milliseconds = 3 seconds
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error, serror]);
  const formik = useFormik({
    initialValues: formdata,
    validationSchema: Yup.object({
      password: Yup.string().required("password Required"),
      newpassword: Yup.string()
        .min(8, "Password must be at least 6 characters")
        .notOneOf(
          [Yup.ref("password")],
          "New Password must be different from Old Password"
        )
        .required("new Password is required"),
      confirmpassword: Yup.string()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values) => {
      // console.log('values',values)
      const userdata = {
        oldpassword: values.password,
        password: values.newpassword,
      };
      await postApi(`admin-changepasswordold`, userdata, true)
        .then((response) => {
          console.log("response", response);
          if (response.status === 200) {
            successNotify(response.data);
            window.location.href = "/searchengines";
          }
          if (response.status === 422) {
            errorNotify(response.data);
            // window.location.href = "/searchengines";
          }
        })
        .catch((error) => {
          errorNotify(error.response.data);
        });
    },
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <section
      className="login-section d-flex  
        align-items-center  
        justify-content-center  
        "
    >
      <div className="w-100">
        <form onSubmit={formik.handleSubmit}>
          <div className="login-feild-wrap">
            <div className="myinput">
              <input
                type="text"
                placeholder="Enter Old Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                className={formik.errors.password ? "errorinput" : ""}
              />
            </div>
          </div>
          <div className="error">
            {formik.touched.password && formik.errors.password ? (
              <p>{formik.errors.password}</p>
            ) : null}
          </div>
          <div className="login-feild-wrap">
            <div className="myinput">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter New Password"
                name="newpassword"
                value={formik.values.newpassword}
                onChange={formik.handleChange}
                className={formik.errors.newpassword ? "errorinput" : ""}
              />
              <span className="eye" onClick={togglePasswordVisibility}>
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </span>
            </div>
          </div>
          <div className="error">
            {formik.touched.newpassword && formik.errors.newpassword ? (
              <p>{formik.errors.newpassword}</p>
            ) : null}
          </div>
          <div className="login-feild-wrap">
            <div className="myinput">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Enter Confirm Password"
                name="confirmpassword"
                value={formik.values.confirmpassword}
                onChange={formik.handleChange}
                className={formik.errors.confirmpassword ? "errorinput" : ""}
              />
              <span className="eye" onClick={toggleConfirmPasswordVisibility}>
                {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
              </span>
            </div>
          </div>
          <div className="error">
            {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
              <p>{formik.errors.confirmpassword}</p>
            ) : null}
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="mybtn">
              Change Password
            </button>
          </div>
          {error ? (
            <div class="text-success mt-5" role="alert">
              {error}
            </div>
          ) : null}
          {serror ? (
            <div class="text-danger mt-5" role="alert">
              {serror}
            </div>
          ) : null}
        </form>
      </div>
    </section>
  );
}

export default ChangePassword;
