import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as CategorySvg } from "../images/category.svg";
import { ReactComponent as VideoSvg } from "../images/videoo.svg";
import Popup from "./Popup";
import * as Yup from "yup";
import MyContext from "./MyContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getApi, postApi } from "./Helper/Axios";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
export default function Wallpapers() {
  let permission_module;
  const location = useLocation();
  const urlName = location.pathname;
  let module = urlName.replace(/^\/|\/$/g, "");

  const user = useSelector((state) => state.auth.user);

  const isSuperAdmin = user && user.is_superadmin;

  const permission = user && JSON.parse(user?.permission);

  if (permission !== null) {
    permission_module = { ...permission[module] };
  }
  if (isSuperAdmin === 1) {
    permission_module = {
      read: true,
      create: true,
      update: true,
      delete: true,
    };
  }
  const [shows, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isupdate, setIsupdate] = useState(false);
  const [categoryname, setCategoryName] = useState("wallpapers");
  const [categoryId, setCategoryId] = useState();
  const [updatedata, setUpdateData] = useState({});
  const [deleteid, setdeleteid] = useState(0);
  const [id, setId] = useState();
  const [siteCategory, setSiteCategory] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [modaltxt, setmodaltxt] = useState("Delete");
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);
  const [dataSite, setDataSite] = useState([]);
  const [error, setError] = useState();
  const [pageLoader, setPageLoader] = useState(false);
  const formConfig = {
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text",
        placeholder: "enter name",
      },
      {
        name: "image",
        label: "Image",
        type: "file",
      },
      {
        name: "colors",
        label: "colors",
        type: "color",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      name: Yup.string().required("name Required"),
      image: Yup.mixed().required("Please upload an image"),
      colors: Yup.string().required("color Required"),
    }),
  };
  const formConfigSites = {
    fields: [
      {
        name: "image",
        label: "Image",
        type: "files",
      },
      {
        name: "colors",
        label: "colors",
        type: "color",
      },
    ],
    validationSchema: Yup.object({
      colors: Yup.string().required("color Required"),
    }),
  };
  const initialValuesSites = {
    image: "",
    colors: "",
  };
  const initialValues = {
    name: "",
    image: "",
    status: false,
    colors: "",
  };
  const successNotify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const errorNotify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const fetchData = async () => {
    setPageLoader(true);
    let url = "get-wallpaper-category";
    await getApi(url, true)
      .then((response) => {
        setSiteCategory(response.data.wallpapercategory);
        setPageLoader(false);
        if (response.status === 401) {
          setError(response.data);
        }
      })
      .catch((err) => {
        console.log("Fetching data errror", err);
        setPageLoader(false);
      });
    await getApi("get-wallpaper", true)
      .then(async (response) => {
        let newData = [...response.data];
        if (response.status === 200) {
          setDataSite(newData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const value = {
    categoryId: categoryId,
    title: categoryname,
    isupdate: isupdate,
    handleClose: handleClose,
    isShow: shows,
    formConfig: categoryname === "wallpapers" ? formConfig : formConfigSites,
    url:
      categoryname === "wallpapers"
        ? "add-wallpaper-category"
        : "add-wallpaper",
    data:
      categoryname === "wallpapers"
        ? isupdate
          ? updatedata
          : initialValues
        : isupdate
        ? updatedata
        : initialValuesSites,
    fetchData: fetchData,
    updatteurl:
      categoryname === "wallpapers" ? "edit-wallpaper-category" : "edit-site",
    id: id,
    axiosHeader: true,
  };
  const handleStatusChange = async (statuss, id, name = null) => {
    let newdata = {
      status: statuss,
    };
    if (name === "wallpaperCategoryselected") {
      newdata = {
        is_selected: statuss,
      };
    }
    let url = name === null ? "status-wallpaper-category" : "status-wallpaper";
    await postApi(`${url}?status=${id}`, newdata, true)
      .then((res) => {
        if (res.status === 200) {
          successNotify(res.message);
          fetchData();
        }
      })
      .catch((err) => console.log(err));
  };
  const handleUpdate = async (id, name = null) => {
    let updateurl = name === null ? "get-wallpaper-category" : "get-site";
    let query = name === null ? "wallpapercategory" : "site";
    await getApi(`${updateurl}?${query}=${id}`, true)
      .then((res) => {
        name === null
          ? setUpdateData(res.data.wallpapercategory)
          : setUpdateData(res.data);
        setIsupdate(true);
        handleShow();
      })
      .catch((err) => console.log(err));
  };
  const handleDelete = async (id) => {
    let deleteurl =
      modaltxt === "Archive"
        ? "archive-wallpaper"
        : "delete-wallpaper-category";
    let query = modaltxt === "Archive" ? "wallpaper" : "category";
    await getApi(`${deleteurl}?${query}=${id}`, true)
      .then((res) => {
        if (res.status === 200) {
          fetchData();
          errorNotify(res.message);
          handleDeleteClose();
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <MyContext.Provider value={value}>
      {error ? (
        <div className="alert-danger text-center alert">
          <p>{error}</p>
        </div>
      ) : (
        <>
          <div>
            <h4 className="d-flex justify-content-between">Wallpapers</h4>
            <div className="sitesWrap">
              <div className="mb-4 d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <CategorySvg />
                  <h6>Category</h6>
                </div>
                {permission_module?.create === true ? (
                  <div
                    className="mybtn"
                    onClick={() => {
                      setCategoryName("wallpapers");
                      setIsupdate(false);
                      handleShow();
                    }}
                  >
                    Add
                  </div>
                ) : null}
              </div>
              <div className="d-flex flex-wrap">
                {!pageLoader ? (
                  siteCategory &&
                  siteCategory.map((category) => (
                    <div
                      className="d-flex sitecategorywrap align-items-center"
                      key={category.id}
                    >
                      <div className="siteCateIcon marginright">
                        <img
                          src={`${process.env.REACT_APP_UPLOADS_URL}images/${category.image}`}
                          alt="siteCat"
                        />
                      </div>
                      <div className="siteCatContent">{category.name}</div>
                      {permission_module?.update === true ? (
                        <div
                          className="iconWrap edit"
                          onClick={() => {
                            setId(category.id);
                            handleUpdate(category.id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentcolor"
                              d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                            />
                          </svg>
                        </div>
                      ) : null}
                      {permission_module?.update === true ? (
                        <div
                          className="iconWrap view"
                          onClick={() =>
                            handleStatusChange(!category.status, category.id)
                          }
                        >
                          {category.status === true ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 640 512"
                            >
                              <path
                                fill="currentcolor"
                                d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"
                              />
                            </svg>
                          )}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                          >
                            <path
                              fill="currentcolor"
                              d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                            />
                          </svg>
                        </div>
                      ) : null}
                      {permission_module?.delete === true ? (
                        <div
                          className="iconWrap"
                          onClick={() => {
                            setdeleteid(category.id);
                            handleDeleteShow();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentcolor"
                              d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                            />
                          </svg>
                        </div>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div className="text-center w-100">
                    <div className="loaderWrap">
                      <div className="loader-15"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!pageLoader ? (
              dataSite &&
              dataSite.map((site) => (
                <div className="sitesWrap align-items-center" key={site.id}>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center mb-3">
                      <div className="svgWrap siteCateIcon marginright">
                        <img
                          src={`${process.env.REACT_APP_UPLOADS_URL}images/${site.image}`}
                          alt="img"
                        />
                      </div>
                      <h6 className="mb-0">{site.name}</h6>
                    </div>
                    {permission_module?.create === true ? (
                      <div
                        className="mybtn"
                        onClick={() => {
                          handleShow();
                          setIsupdate(false);
                          setCategoryName("wall");
                          setCategoryId(site.id);
                        }}
                      >
                        Add
                      </div>
                    ) : null}
                  </div>
                  <div className="sites_list">
                    <div className="d-flex flex-wrap">
                      {site.categoryData?.length > 0 ? (
                        site.categoryData.map((category, categoryIndex) => {
                          return (
                            <div
                              className="d-flex sitecategorywrap bg Wallpaper align-items-center"
                              key={category.id}
                            >
                              <div className="siteCateIcon Wallpaper marginright">
                                <img
                                  src={`${process.env.REACT_APP_UPLOADS_URL}images/${category.image}`}
                                  alt="siteCat"
                                />
                                <div className="wallpaper-wrap-category">
                                  {permission_module?.update === true ? (
                                    <div
                                      className="iconWrap edit"
                                      onClick={() => {
                                        handleStatusChange(
                                          !category.is_selected,
                                          category.id,
                                          "wallpaperCategoryselected"
                                        );
                                      }}
                                    >
                                      {category.is_selected === 1 ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 640 512"
                                        >
                                          <path
                                            fill="currentcolor"
                                            d="M323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L512 316.8V128h-.7l-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2zm22.8 124.4l-51.7 40.2C263 274.4 217.3 268 193.7 235.6c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48V352h28.2l91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8l-134.2-123zM16 128c-8.8 0-16 7.2-16 16V352c0 17.7 14.3 32 32 32H64c17.7 0 32-14.3 32-32V128H16zM48 320a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM544 128V352c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V144c0-8.8-7.2-16-16-16H544zm32 208a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 640 512"
                                        >
                                          <path
                                            fill="currentcolor"
                                            d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7l-135-105.8c-1.1-11.3-6.3-22.3-15.3-30.7l-134.2-123-23.4 18.2-26-20.3 77.2-60.1c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L512 316.8V128h-.7l-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2l-89.7 72.6-25.8-20.3 81.8-66.2c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-35.5 23.7L38.8 5.1zM413.6 421.9L128 196.8V352h28.2l91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c.8-.8 1.5-1.7 2.2-2.6zM96 171.6L40.6 128H16c-8.8 0-16 7.2-16 16V352c0 17.7 14.3 32 32 32H64c17.7 0 32-14.3 32-32V171.6zM48 320a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM544 128V352c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V144c0-8.8-7.2-16-16-16H544zm32 208a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"
                                          />
                                        </svg>
                                      )}
                                    </div>
                                  ) : null}
                                  {permission_module?.update === true ? (
                                    <div
                                      className="iconWrap view"
                                      onClick={() => {
                                        handleStatusChange(
                                          !category.status,
                                          category.id,
                                          "wallpaperCategory"
                                        );
                                      }}
                                    >
                                      {category.status === true ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 576 512"
                                        >
                                          <path
                                            fill="currentcolor"
                                            d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 640 512"
                                        >
                                          <path
                                            fill="currentcolor"
                                            d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"
                                          />
                                        </svg>
                                      )}
                                    </div>
                                  ) : null}
                                  {permission_module?.delete === true ? (
                                    <div
                                      className="iconWrap"
                                      onClick={() => {
                                        setmodaltxt("Archive");
                                        setdeleteid(category.id);
                                        handleDeleteShow();
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                      >
                                        <path
                                          fill="currentcolor"
                                          d="M32 32H480c17.7 0 32 14.3 32 32V96c0 17.7-14.3 32-32 32H32C14.3 128 0 113.7 0 96V64C0 46.3 14.3 32 32 32zm0 128H480V416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V160zm128 80c0 8.8 7.2 16 16 16H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"
                                        />
                                      </svg>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-md-12">
                          <div className="not-found">
                            <p>No data found</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center w-100">
                <div className="loaderWrap">
                  <div className="loader-15"></div>
                </div>
              </div>
            )}
          </div>
          {shows && <Popup />}
          <Modal show={showDelete} onHide={handleDeleteClose}>
            <Modal.Header closeButton>
              <Modal.Title>{modaltxt}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to {modaltxt} this {categoryname}.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="mybtn black"
                onClick={handleDeleteClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="mybtn"
                onClick={() => handleDelete(deleteid)}
              >
                {modaltxt}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </MyContext.Provider>
  );
}
