import React, { useState } from "react";
import * as Yup from "yup";
import TableDisplay from "./TableDisplay";

export default function Game() {
  const [title, setTitle] = useState(["id", "name", "url", "icon", "status"]);
  const formConfig = {
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text",
        placeholder: "enter name",
      },
      {
        name: "icon",
        label: "Icon",
        type: "file",
      },
      {
        name: "url",
        label: "Url",
        type: "text",
        placeholder: "enter name",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      name: Yup.string().required("name Required"),
      url: Yup.string().required("url Required"),
    }),
  };

  const initialValues = {
    name: "",
    url: "",
    status: false,
    icon: "",
  };
  return (
    <div>
      <TableDisplay
        category="Game"
        tableurl="get-game"
        title={title}
        deleteurl="archive-game"
        deleteallurl="all-archive-game"
        addurl="add-game"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-game"
        updatteurl="edit-game"
        serachCategory="name"
        statusurl="status-game"
        axiosHeader={true}
      />
    </div>
  );
}
