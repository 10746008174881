import React, { useState } from "react";
import TableDisplay from "./TableDisplay";

export default function User() {
  const [title, setTitle] = useState(["id", "device_id", "country", "state", "city", "isp", "org", "packagename", "splash_users_by", "createdAt"]);
  
  return (
    <div>
      <TableDisplay
        category="Exspeed User"
        tableurl="users"
        title={title}
        serachCategory="(Country, State, city, Isp, Org, Packagename)"
        statusurl="status-search-engine"
        axiosHeader={true}
      />
    </div>
  );
}
