import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Components/Login";
import Layout from "./Components/Layout";
import Signup from "./Components/Signup";
import ForgotPassword from "./Components/ForgotPassword";
import { Provider } from "react-redux";
import store from "./redux/store";
import SettingPermission from "./Components/SettingPermission";
import Moreapps from "./Components/Moreapps";
import Dashboard from "./Components/dashboard";
import User from "./Components/User";
import KoiUser from "./Components/KoiUser";
import SearchEngine from "./Components/SearchEngine";
import TrendingSearch from "./Components/TrendingSearch";
import Game from "./Components/Game";
import Sites from "./Components/Sites";
import Wallpapers from "./Components/Wallpapers";
import Archive from "./Components/Archive";
import Top from "./Components/Top";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Provider store={store}>
        <Login />
      </Provider>
    ),
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },
  {
    path: "/settings",
    element: (
      <Layout>
        <SettingPermission />
      </Layout>
    ),
  },
  {
    path: "/moreapps",
    element: (
      <Layout>
        <Moreapps />
      </Layout>
    ),
  },
  {
    path: "/trendingsearches",
    element: (
      <Layout>
        <TrendingSearch />
      </Layout>
    ),
  },
  {
    path: "/games",
    element: (
      <Layout>
        <Game />
      </Layout>
    ),
  },
  {
    path: "/top",
    element: (
      <Layout>
        <Top />
      </Layout>
    ),
  },
  {
    path: "/wallpapers",
    element: (
      <Layout>
        <Wallpapers />
      </Layout>
    ),
  },
  {
    path: "/sites",
    element: (
      <Layout>
        <Sites />
      </Layout>
    ),
  },
  {
    path: "/archive",
    element: (
      <Layout>
        <Archive />
      </Layout>
    ),
  },
  {
    path: "/searchengines",
    element: (
      <Layout>
        <SearchEngine />
      </Layout>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <Layout>
        <Dashboard />
      </Layout>
    ),
  },
  {
    path: "/users",
    element: (
      <Layout>
        <User />
      </Layout>
    ),
  },
  {
    path: "/koi-users",
    element: (
      <Layout>
        <KoiUser />
      </Layout>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
